
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useMobileIdLoginMutation,
  useMobileIdStatusMutation
} from "../../../redux/api.js";
import {
  completeSmartIdLogin,
  failSmartIdLogin,
  selectErrorMessage,
  startSmartIdLogin
} from "../../../redux/slices/smartIdSlice.js";

export const MobileId = () => {
  const navigate = useNavigate();
  const [personal_code, setPersonalCode] = useState('');
  const [phone_number, setPhoneNumber] = useState('');
  const error = useSelector((state) => state.smartId.error);
  const [errorPersonalCode, setErrorPersonalCode] = useState('');
  const [errorPhoneNumber, setErrorPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [mobileIdLogin, result] = useMobileIdLoginMutation();  // Changed 'result' to be for login status
  const [mobileIdStatus, resultStatus] = useMobileIdStatusMutation();  // Used for status checking
  const dispatch = useDispatch();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [customErrorMessage, setCustomErrorMessage] = useState(''); // Custom error message state
  const [isInputVisible, setIsInputVisible] = useState(true); // Control input visibility
  const errorMessage = useSelector(selectErrorMessage);

  // Function to handle the mobile ID login
  const handleMobileIdLogin = async () => {
    let validationErrors = [];

    // Validate personal code
    if (!personal_code || !/^\d{11}$/.test(personal_code)) {
      setErrorPersonalCode('Kehtetu isikukood. Sisestage kehtiv 11-kohaline isikukood.');
      setTimeout(() => setErrorPersonalCode(''), 5000);
      validationErrors.push('Kehtetu isikukood.');
    } else {
      setErrorPersonalCode('');
    }

    // Validate phone number
    if (!phone_number || !/^\+372\d{8,}$/.test(phone_number)) {
      setErrorPhoneNumber('Kehtetu telefoninumber. Sisestage kehtiv telefoninumber algusega +372.');
      setTimeout(() => setErrorPhoneNumber(''), 5000);
      validationErrors.push('Kehtetu telefoninumber.');
    } else {
      setErrorPhoneNumber('');
    }

    if (validationErrors.length > 0) {
      return; // Exit early if validation failed
    }

    dispatch(startSmartIdLogin());

    try {
      const loginResult = await mobileIdLogin({
        phoneNumber: phone_number,
        personalCode: personal_code,
      });

      if (loginResult.data) {
        const status = loginResult.data[0]?.status;
        if (status === '0' || '') {
          setCustomErrorMessage('Puuduvad õigused sisselogimiseks. Võta ühendust Astro Balticsiga.');
          setShowErrorMessage(true);
          setIsInputVisible(false); // Hide inputs

          // Hide the error after 5 seconds and show inputs again
          setTimeout(() => {
            setShowErrorMessage(false);
            setIsInputVisible(true);
          }, 5000);
        } else {
          dispatch(completeSmartIdLogin(loginResult.data));
          setVerificationCode(loginResult.data.verificationCode);
        }
      } else {
        dispatch(failSmartIdLogin());
        handleErrorMessage('Viga sisselogimisel');
      }
    } catch (error) {
      if (error.response?.status === 403) {
        handleErrorMessage('Isikukood ei ole leidnud. Palun kontrollige oma andmeid.');
      } else {
        handleErrorMessage('Viga sisselogimisel');
      }
    }
  };

  // Function to handle and show custom error messages
  const handleErrorMessage = (message) => {
    setCustomErrorMessage(message);
    setShowErrorMessage(true);
    setIsInputVisible(false); // Hide inputs

    // Hide the error after 5 seconds and show inputs again
    setTimeout(() => {
      setShowErrorMessage(false);
      setIsInputVisible(true);
    }, 5000);
  };

  // If `showErrorMessage` is true, render the error block
  if (showErrorMessage) {
    return (
      <div style={{ color: '' }}>
        <div>Puuduvad õigused sisselogimiseks.</div>
        <div>Vajadusel võta ühendust Astro Balticsiga.</div>
        <div
          style={{
            fontSize: '18px',
            marginTop: '10px',
            fontWeight: '600'
          }}
        >
          info@astrobaltics.eu +372 628 000
        </div>
      </div>
    );
  }

  return (
    <div className="smart-id-container">
      <div className="verification-container">
        {verificationCode ? (
          <>
            <div>Sinu kontrollkood</div>
            <div className="verification-input">
              <input
                style={{
                  border: 'none',
                  textAlign: 'center',
                  backgroundColor: '#f1f1f1',
                  fontSize: '40px',
                  boxSizing: 'border-box',
                  maxWidth: '100%',
                  width: '100%',
                }}
                type="text"
                placeholder="Verification Code"
                className="verification-code-input"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
              />
            </div>
            <div>Päring on saadetud Sinu seadmesse.</div>
            <div>Võrdle ja sisesta PIN1</div>
          </>
        ) : (
          // Conditionally render the input section based on `isInputVisible`
          isInputVisible && (
            <div className="input-section">
              <input
                type="text"
                placeholder="Isikukood"
                className="smart-id-input"
                value={personal_code}
                onChange={(e) => setPersonalCode(e.target.value)}
                autoComplete="on"
              />
              {errorPersonalCode && <div style={{ color: 'red', marginBottom: '-24px' }}>{errorPersonalCode}</div>}
              <br />
              <input
                type="text"
                placeholder="Telefoninumber algusega +372"
                className="smart-id-input mt-2"
                value={phone_number}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              {errorPhoneNumber && <div style={{ color: 'red', marginBottom: '-24px' }}>{errorPhoneNumber}</div>}
              <br />
              <button className="smart-id-button mt-3" onClick={handleMobileIdLogin}>
                SISENE
              </button>
            </div>
          )
        )}

        {error && result.isError && showErrorMessage && (
          <div className="error-message" style={{ color: 'red' }}>
            {customErrorMessage || errorMessage || 'Viga sisselogimisel'}
          </div>
        )}
      </div>
    </div>
  );
};
