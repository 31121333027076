import React, { useEffect } from 'react';
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import CompanyCard from '../loggedIn/CompanyCard';

export const CardGrid = ({ filteredCompanies }) => {
  useEffect(() => {
  }, [filteredCompanies]);

if (!Array.isArray(filteredCompanies) || filteredCompanies.length === 0) {
  return <p>Kasuta otsingut etevõtete leidmiseks.</p>;
}
  
  return (
    <Container>
      <Row>
        {filteredCompanies.map((company, index) => (
          <Col key={index} xs={12} sm={6}>
            <CompanyCard
              companyName={company.name}
              address={company.address}
              registrikood={company.regno}
              contacts={company.contacts}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};
