import React, { useState, useEffect } from "react";
import Accordion from 'react-bootstrap/Accordion';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PasswordSection from "./PasswordSection.js";

export const CardList = ({ filteredCompanies }) => {
  useEffect(() => {
  }, [filteredCompanies]);
  
  if (!Array.isArray(filteredCompanies) || filteredCompanies.length === 0) {
    return <p>Kasuta otsingut etevõtete leidmiseks.</p>;
  }
  
  return (
    <Container>
      <Row>
        {filteredCompanies.map((company, index) => (
          <Col
            key={index} md={6} style={{
            marginBottom: '5px',
            marginLeft: '0',
            paddingLeft: '0'
          }}
          >
            <Accordion>
              <Accordion.Item eventKey={index} className="list-style">
                <Accordion.Header className="list-title">
                  <h5 className="card-title list-title">{company.name}</h5>
                </Accordion.Header>
                <Accordion.Body>
                  
                  <p className="card-text" style={{lineHeight: '0.5'}}>
                    Aadress:
                  </p>
                  <p className="card-text" style={{lineHeight: '0.5'}}>
                    Registrikood: {company.regno}
                  </p>
                  <hr style={{width: '90%'}}/>
                  <p
                    className="card-text" style={{
                    fontWeight: 'bold',
                    fontSize: '18px'
                  }}
                  >
                    Kontakt
                  </p>
                  <Row>
                    <Col xs={12} sm={8} style={{lineHeight: '0.6'}}>
                      <div className="tableContainerStyle">
                        <table
                          className="table" style={{
                          border: 'transparent',
                          fontSize: '14px'
                        }}
                        >
                          {/* Display contacts data here */}
                        </table>
                      </div>
                    </Col>
                    <Col xs={12} sm={4} className="d-flex justify-content-center">
                      <PasswordSection registrikood={company.regno} />
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        ))}
      </Row>
    </Container>
  );
};
