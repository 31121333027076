import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import PasswordSection from "./PasswordSection.js";

const CompanyCard = ({
  companyName,
  address,
  registrikood
}) => {
  
  return (
    <Card className="cardGrid">
      <Card.Body style={{}}>
        <h5 className="card-title list-title mb-3 pt-3">{companyName}</h5>
        <p className="card-text" style={{ lineHeight: '0.5' }}>
          Aadress: {address}
        </p>
        <p className="card-text" style={{ lineHeight: '0.5' }}>
          Registrikood: {registrikood}
        </p>
        <hr style={{ width: '90%' }} />
        <p
          className="card-text"
          style={{
            fontWeight: 'bold',
            fontSize: '18px'
          }}
        >
          Kontakt
        </p>
        <Row>
          <Col xs={12} sm={8} style={{ lineHeight: '0.6' }}>
            <div className="tableContainerStyle">
              <table
                className="table"
                style={{
                  border: 'transparent',
                  fontSize: '14px'
                }}
              >
                {/* Display contacts data here */}
              </table>
            </div>
          </Col>
          <PasswordSection registrikood={registrikood}
          />
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CompanyCard;
