import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import {
  useNavigate,
  useParams
} from 'react-router-dom';
import {
  useIdCardLoginQuery
} from '../../../redux/api.js';

export const IdCard = () => {
  const params = useParams();
  console.log(params.id);
  const {data, isSuccess, isError} = useIdCardLoginQuery(params?.id, {skip: !params?.id});
  console.log(data, isSuccess, isError);
  const navigate = useNavigate();
  
  
  useEffect(() => {
    if (isSuccess && data && data.status === 200) {
      const userData = data.data?.user;
      
      if (userData) {
        console.log('User Data:', userData);
        
        // Perform any necessary logic with user data
        
        // For example, if you want to navigate to '/logged':
        navigate('/logged');
      } else {
        console.error('User data not found in the response:', data);
      }
    } else if (isError) {
      // Handle error if needed
      console.error('Error occurred:', isError);
    }
  }, [isSuccess, isError, data, navigate]);
  
  console.log(data, isSuccess, isError);
  
  return (
    <div className="id-card-container">
      <Card.Subtitle className="mb-2 mt-2 text-muted">
        Sisesta ID-kaart kaardilugejasse ja vajuta
        <br /> nuppu "Sisene"
      </Card.Subtitle>
      <button className="smart-id-button mt-3">
        <a href="https://idauth.portal.noom.ee" className="smart-id-button">
          SISENE
        </a>
      </button>
    </div>
  );
};
