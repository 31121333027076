import React from 'react';
import {NavigationBar} from "./NavigationLogo.js";

const NotFoundPage = () => {
  return (
    <>
      <NavigationBar/>
      <div className="notFoundPageStyle">
        <p
          style={{
            color: '#FBB615',
            fontSize: '20px'
          }}
        >404 | Lehekülge ei leitud</p>
      </div>
    </>
  );
};

export default NotFoundPage;
