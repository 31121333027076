import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import {
  useDispatch,
  useSelector
} from "react-redux";
import logo from '../../assets/images/astro_baltics.svg';
import arrowDown from '../../assets/images/arrow_down.svg';
import { Dropdown } from "react-bootstrap";
import {selectUser, logout} from "../../redux/slices/authSlice.js";
import { useNavigate } from 'react-router-dom';

export const NavbarSection = ({  }) => {
  const [isOpen, setIsOpen] = useState(false);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  
  const handleLogout = () => {
    dispatch(logout());
   
    navigate('/login/smart-id');
  };
  
  return (
    <div>
      <Navbar className="custom-navbar" expand="lg">
        <Container>
          <Navbar.Brand href="#">
            <img
              src={logo}
              width="140"
              height="36"
              className="d-inline-block"
              alt="Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar-nav"/>
          <Navbar.Collapse id="navbar-nav" style={{fontSize: '16px'}}>
            <Nav className="ml-auto">
              {/*<Nav.Link href="#" className="upper-name">*/}
              {/*  ESILEHT*/}
              {/*</Nav.Link>*/}
              {/*<Nav.Link href="" className="upper-name">*/}
              {/*  MINU ETTEVÕTTED*/}
              {/*</Nav.Link>*/}
              {/*<Nav.Link href="#" className="upper-name">*/}
              {/*  ARVED*/}
              {/*</Nav.Link>*/}
              {/*<Nav.Link href="#" className="upper-name">*/}
              {/*  TÖÖDE NIMEKIRI*/}
              {/*</Nav.Link>*/}
              <Nav.Link href="#" className="upper-name">
                HOOLDUS
              </Nav.Link>
            </Nav>
            
          </Navbar.Collapse>
          {user ? (
          <Dropdown onToggle={toggleDropdown} className="ml-auto" style={{textAlign: 'right'}}>
            <Dropdown.Toggle
              variant=""
              id="dropdown-basic"
              className={`orange-name ${
                isOpen ? "rotate-down" : ""
              }`
              }
              nocaret
            >
              {/*{user.loginMethod === 'smart-id' ? 'Smart-ID User' : user.customerName}*/}
              {user.firstname} {user.lastname}
              <img
                src={arrowDown}
                width="10"
                height="10"
                alt="Dropdown Arrow"
                className={`arrow-down ${isOpen ? "arrow-up" : ""}`}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={handleLogout}>Logi välja</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          ) : null}
        </Container>
      </Navbar>
    </div>
  );
}
