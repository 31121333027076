
import React, { useState, useEffect } from 'react';
import { Outlet, useLocation, } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import line from '../assets/images/line.svg';
import lineActive from '../assets/images/line_active.svg';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import { useNavigate } from 'react-router-dom';
import {NavigationBar} from "./NavigationLogo.js";

export const LoginPage = () => {
  const [scrolling, setScrolling] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  
  // Set the default active button to 'smart-id'
  const [activeButton, setActiveButton] = useState('smart-id');
  
  const handleButtonClick = (buttonName) => {
    navigate(`/login/${buttonName}`);
    setActiveButton(buttonName); // Update the active button state
  };
  
  const handleScroll = () => {
    if (window.scrollY > 100) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  return (
    <Container fluid>
      <NavigationBar />
      
      <Row className="vh-100 justify-content-center align-items-center">
        <Col md={6} lg={4}>
          <Card
            style={{
              width: '',
              border: 'none',
              marginTop: '-227px',
            }}
            className={`log-in-card text-center ${
              scrolling ? 'small-card' : ''
            }`}
          >
            <Card.Body>
              <Card.Title
                className={`h1-title text-center ${
                  scrolling ? 'small-title-h1' : ''
                }`}
              >
                JÄTKAMISEKS VALI
                <br /> TUVASTAMISE VIIS
              </Card.Title>
              
              <Card.Subtitle className="mb-2 text-muted">
                Kui kliendikontot ei ole, saad selle registreerida pärast tuvastamist
              </Card.Subtitle>
              
              <ButtonToolbar className="justify-content-center mt-1">
                {[
                  { name: 'smart-id', label: 'Smart-ID' },
                  { name: 'mobile-id', label: 'Mobiil-ID' },
                  { name: 'id-card', label: 'ID-kaart' },
                ].map((button) => (
                  <button
                    key={button.name}
                    className={`btn ${activeButton === button.name ? 'active' : ''}`}
                    onClick={() => handleButtonClick(button.name)}
                    style={{
                      minHeight: '70px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: activeButton === button.name ? 'none' : 'none',
                      borderRadius: '5px',
                    }}
                  >
                    <span style={{ marginBottom: '5px' }}>{button.label}</span>
                    <img
                      src={activeButton === button.name ? lineActive : line}
                      alt={button.label}
                      className={`${activeButton === button.name ? 'active-image' : 'inactive-image'}`}
                    />
                  </button>
                ))}
              </ButtonToolbar>
              
              <Outlet />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
