// src/slices/smartIdSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  loading: false,
  error: false,
  loggedIn: false,
  errorMessage: null,
};

const smartIdSlice = createSlice({
  name: 'smartId',
  initialState,
  reducers: {
    startSmartIdLogin: (state, test) => {
      state.loading = true;
      state.user = null;
      state.error = false;
      state.loggedIn = false;
      state.errorMessage = null;
    },
    completeSmartIdLogin: (state, action) => {
      state.user = action.payload;
      state.loading = false;
      state.error = false;
      state.loggedIn = true;
      state.errorMessage = null;
    },
    failSmartIdLogin: (state, action) => {
      state.user = null;
      state.loading = false;
      state.error = true;
      state.errorMessage = action.payload || 'Viga sisselogimisel';
 
    },
  },
});

export const { startSmartIdLogin, completeSmartIdLogin, failSmartIdLogin } = smartIdSlice.actions;
export const selectLoggedIn = (state) => state.smartId.loggedIn;
export const selectErrorMessage = (state) => state.smartId.errorMessage;
export default smartIdSlice.reducer;
