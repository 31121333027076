import React from 'react';
import { Navbar } from 'react-bootstrap';
import logo from '../assets/images/astro_baltics.svg';

export const NavigationBar = () => {
  return (
    <Navbar bg="" expand="lg" className="justify-content-center">
      <Navbar.Brand href="/" className="d-flex justify-content-center">
        <img src={logo} alt="Logo" style={{ maxHeight: '30px' }} />
      </Navbar.Brand>
    </Navbar>
  );
};

