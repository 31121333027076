import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useSelector } from 'react-redux';
import { CompaniesButtons } from './CompaniesButtons';
import { NavbarSection } from './NavbarSection';
import { SearchSection } from './SearchSection';
import { selectIsAuthenticated } from '../../redux/slices/authSlice';
import { useNavigate } from 'react-router-dom';

export const LoggedInPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const navigate = useNavigate();
  
  const handleSearch = (updatedFilteredCompanies) => {
    setFilteredCompanies(updatedFilteredCompanies);
  };
  
  useEffect(() => {
    const handleKeyDown = (event) => {
      
      if (event.keyCode === 116 || event.code === 'F5') {
        event.preventDefault(); // Prevent the default browser behavior
        setSearchQuery(''); // Reset the searchQuery when F5 is pressed
        setFilteredCompanies([]); // Reset the filteredCompanies when F5 is pressed
      }
    };
    
    // Add event listener when the component mounts
    window.addEventListener('keydown', handleKeyDown);
    
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []); // Empty dependency array ensures that the effect runs only once when the component mounts
  
  useEffect(() => {
    // Redirect to the login page if the user is not authenticated
    if (!isAuthenticated) {
      navigate('/logged'); // Adjust the path as needed
    }
  }, [isAuthenticated, navigate]);
  
  return (
    <div>
      <NavbarSection />
      <SearchSection setSearchQuery={setSearchQuery} onSearch={handleSearch} />
      <Container>
        <CompaniesButtons filteredCompanies={filteredCompanies} />
      </Container>
    </div>
  );
};
