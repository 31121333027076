import React, {
  useEffect,
  useState
} from 'react';
import {
  useSmartIdLoginMutation,
  useSmartIdStatusMutation,
} from '../../../redux/api';
import {useNavigate} from 'react-router-dom';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  completeSmartIdLogin,
  failSmartIdLogin,
  selectErrorMessage,
  startSmartIdLogin,
} from '../../../redux/slices/smartIdSlice.js';

export const SmartId = () => {
  const navigate = useNavigate();
  const error = useSelector((state) => state.smartId.error);
  const errorMessage = useSelector(selectErrorMessage);
  const [personal_code, setPersonalCode] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [smartIdLogin, result] = useSmartIdLoginMutation();
  const [smartIdStatus, resultStatus] = useSmartIdStatusMutation();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorPersonalCode, setErrorPersonalCode] = useState('');
  const dispatch = useDispatch();
  
  useEffect(() => {
    let timer;
    const maxTimeout = 30000;
    const startTime = Date.now();
    let shouldCheckStatus = true;
    
    const checkVerificationStatus = async () => {
      try {
        if (verificationCode && shouldCheckStatus) {
          const statusResult = await smartIdStatus(verificationCode);
          
          if (statusResult.data && statusResult.data.message === 'Login successful') {
            clearInterval(timer);
            shouldCheckStatus = false;
            navigate('/logged');
          } else {
            const currentTime = Date.now();
            const elapsedTime = currentTime - startTime;
            
            if (elapsedTime >= maxTimeout) {
              clearInterval(timer);
              shouldCheckStatus = false;
              
              const errorMessage = statusResult.data
                ? statusResult.data.message
                : 'Viga sisselogimisel';
              dispatch(failSmartIdLogin(errorMessage));
            }
          }
        }
      } catch (error) {
        console.error('An error occurred:', error);
        clearInterval(timer);
        shouldCheckStatus = false;
        const errorMessage = 'Viga sisselogimisel';
        dispatch(failSmartIdLogin(errorMessage));
      }
    };
    
    // Initial check
    checkVerificationStatus();
    
    // Set an interval to check the verification status every 5 seconds
    timer = setInterval(checkVerificationStatus, 5000);
    
    return () => {
      clearInterval(timer);
    };
  }, [verificationCode, navigate, dispatch, smartIdStatus, result.isError]);
  
  
  useEffect(() => {
      if (result.isError) {
        setShowErrorMessage(true);
        setTimeout(() => {
          setShowErrorMessage(false);
          navigate('/login/smart-id');
        }, 5000);
      }
    },
    [
      result.isError,
      navigate
    ]
  );
  
  const handleSmartIdLogin = async () => {
    if (!personal_code || !/^\d{11}$/.test(personal_code)) {
      // Handle validation error, e.g., show an error message
      console.log('Kehtetu isikukood. Sisestage kehtiv 11-kohaline isikukood.');

      setErrorPersonalCode('Kehtetu isikukood. Sisestage kehtiv 11-kohaline isikukood.');
      console.log('hello', errorPersonalCode);
      setTimeout(() => {
        setErrorPersonalCode('');
        console.log('Error cleared.');
      }, 5000);
    } else {
      setErrorPersonalCode(''); // Clear the error

      // Dispatch the startSmartIdLogin action to set loading to true
      dispatch(startSmartIdLogin());
  
    try {
      const loginResult = await smartIdLogin(personal_code);
      console.log("Smart id personal_code", personal_code);
      console.log("Smart id login", loginResult.data);
    
      if (loginResult.data) {
        if (loginResult.status >= 300 && loginResult.status < 400) {
          console.log('Received a redirection response, but not redirecting');
        } else {
          const status = loginResult.data[0]?.status;
  
          if (status === '0') {
            
            return (
              <div>
                <div>Puuduvad õigused sisselogimiseks.</div>
                <div>Vajadusel võta ühendust Astro Balticsiga.</div>
                <div
                  style={{
                    fontSize: '18px',
                    marginTop: '10px',
                    fontWeight: '600'
                  }}
                >
                  info@astrobaltics.eu +372 628 000
                </div>
              </div>
            );
          } else {
            // Dispatch the completeSmartIdLogin action with the user data
            dispatch(completeSmartIdLogin(loginResult.data));
            setVerificationCode(loginResult.data.verificationCode);
          }
        }
      } else {
        dispatch(failSmartIdLogin());
      }
    } catch (error) {
      console.log('ERROR', error);
      // Handle any exceptions here, e.g., network errors
      dispatch(failSmartIdLogin());
    }
    }
  };
  
  if (result.isLoading) {
    return <div>Laeb...</div>;
  }
  
  if (showErrorMessage) {
    return (
      <div style={{color: ''}}>
        <div>Puuduvad õigused sisselogimiseks.</div>
        <div>Vajadusel võta ühendust Astro Balticsiga.</div>
        <div
          style={{
            fontSize: '18px',
            marginTop: '10px',
            fontWeight: '600'
          }}
        >
          info@astrobaltics.eu +372 628 000
        </div>
      </div>
    );
  }
  
  return (
    <div className="smart-id-container">
      <div className="verification-container">
        {verificationCode ? (
          <>
            Sinu kontrollkood
            <div className="verification-input">
              <input
                type="text"
                placeholder="Verification Code"
                className="verification-code-input smartIdInput"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
              />
            </div>
            <div>Päring on saadetud Sinu seadmesse.</div>
            <div>Võrdle ja sisesta PIN1</div>
          </>
        ) : (
          <div className="input-section">
            <input
              type="text"
              placeholder="Isikukood"
              className="smart-id-input"
              value={personal_code}
              onChange={(e) => setPersonalCode(e.target.value)}
              autoComplete="on"
            />
            {errorPersonalCode && <div style={{color: 'red', marginBottom: '-24px'}}>{errorPersonalCode}</div>}
            <br/>
            <button className="smart-id-button mt-3" onClick={handleSmartIdLogin}>
              SISENE
            </button>
          </div>
        )}
        {error && resultStatus.isError && showErrorMessage && (
          <div className="error-message" style={{ color: 'red' }}>
            {errorMessage || 'Viga sisselogimisel'}
          </div>
        )}
      
      </div>
    </div>
  );
  
};

