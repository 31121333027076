import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import grid from '../../assets/images/grid.svg';
import grid_active from '../../assets/images/grid_active.svg';
import list from '../../assets/images/list.svg';
import list_active from '../../assets/images/list_active.svg';
import {CardGrid} from './CardGrid';
import {CardList} from './CardList';

export const  CompaniesButtons = ({ filteredCompanies }) => {
  const [activeButton, setActiveButton] = useState("button1");
 
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };
  
  return (
    <Container>
      <Row className="align-items-center" style={{marginTop: '-30px'}}>
        {/* Title on the left */}
        <Col xs={6}>
          <h1 className="h1-title">HOOLDUS</h1>
        </Col>
        {/* Buttons on the right */}
        <Col xs={6}>
          <div className="btn-group" style={{ float: "right" }}>
            <button
              className={`btn ${
                activeButton === "button1" ? "active" : ""
              } btn-no-outline`}
              onClick={() => handleButtonClick("button1")}
            >
              <img
                src={activeButton === "button1" ? grid_active : grid}
                alt="Button 1"
                className={`${
                  activeButton === "button1"
                    ? "active-image"
                    : "inactive-image"
                } marginTopButtons`}
              />
              <span style={{ color: "transparent", fontSize: '25px' }} className="button-label">Bu</span>
            </button>
            <button
              className={`btn ${
                activeButton === "button2" ? "active" : ""
              } btn-no-outline`}
              onClick={() => handleButtonClick("button2")}
            >
              <img
                src={activeButton === "button2" ? list_active : list}
                alt="Button 2"
                className={`${
                  activeButton === "button2"
                    ? "active-image"
                    : "inactive-image"
                } marginTopButtons`}
              />
              <span style={{ color: "transparent", fontSize: '25px' }} className="button-label">Bu</span>
            </button>
          </div>
        </Col>
      </Row>
      {activeButton === "button1" ? <CardGrid filteredCompanies={filteredCompanies} /> : <CardList filteredCompanies={filteredCompanies} />}
    </Container>
  
  );
}

