// authSlice.js

import { createSlice } from '@reduxjs/toolkit';
import { noomApi } from "../api.js";

const initialState = {
  user: null,
  isAuthenticated: false,
  isAllowed: false,
  verificationCode: null,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    loginSuccess: (state, action) => {
      state.username = action.payload.username;
      state.isAuthenticated = true;
    },
    setAuthentication: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setIsAllowed: (state, action) => {
      state.isAllowed = action.payload;
    },
    startAuth: (state) => {
      state.loading = true;
      state.user = null;
      state.error = null;
      state.loggedIn = false;
    },
    loginFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    logout: (state) => {
      state.username = null;
      state.isAuthenticated = false;
      state.isAllowed = false;
      state.verificationCode = null;
    },
  },
  extraReducers: (builder) => {
    
    builder
      .addMatcher(noomApi.endpoints.smartIdStatus.matchFulfilled, (state, action) => {
        state.user = action.payload.data.user;
      })
      .addMatcher(noomApi.endpoints.mobileIdStatus.matchFulfilled, (state, action) => {
        state.user = action.payload.data.user;
      })
      .addMatcher(noomApi.endpoints.idCardLogin.matchFulfilled, (state, action) => {
        state.user = action.payload.data.user;
      })
      
      .addMatcher(noomApi.endpoints.authorize.matchPending, (state, action) => {
        // Handle pending actions
      })
      .addMatcher(noomApi.endpoints.authorize.matchFulfilled, (state, action) => {
        // Handle fulfilled actions
      })
      .addMatcher(noomApi.endpoints.authorize.matchRejected, (state, action) => {
        // Handle rejected actions
      })
      .addMatcher(noomApi.endpoints.isAllow.matchFulfilled, (state, action) => {
        // Handle isAllow fulfilled actions
      });
  },
});

export const { loginSuccess, setAuthentication, setIsAllowed, startAuth, loginFailure, logout } = authSlice.actions;
// export const { setAuthentication } = authSlice.actions;
export default authSlice.reducer;

// Selectors
export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;

export const selectUser = state => state.auth.user;