import React, {
  useState,
  useEffect
} from 'react';
import {Button} from 'react-bootstrap';
import {useGetPasswordQuery} from "../../redux/api.js";

const PasswordSection = ({registrikood}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [copied, setCopied] = useState(false);
  
  const {
    data: password,
    error: passwordError,
    isLoading: passwordIsLoading
  } = useGetPasswordQuery(registrikood);
  
  useEffect(() => {
    },
    [
      passwordIsLoading,
      password
    ]
  );
  
  const handlePassword = () => {
    setShowPassword(!showPassword);
    setTimeout(() => {
      setShowPassword(false);
    }, 5000);
  };
  
  const handleCopyPassword = () => {
    if (password) {
      navigator.clipboard.writeText(Object.values(password)
                                          .join(''))
               .then(() => {
                 setCopied(true);
                 setTimeout(() => {
                   setCopied(false);
                   setShowPassword(false);
                 }, 5000);
               });
    }
  };
  
  return (
    <div>
      {showPassword ? (
        passwordIsLoading ? (
          <p>Loading password...</p>
        ) : passwordError ? (
          <p>Error loading password: {passwordError.message}</p>
        ) : !password ? (
          <p>No password data available.</p>
        ) : (
          <div>
            <Button className="smart-id-button" onClick={handleCopyPassword}>
              {Object.values(password)
                     .join('')}
              {copied && <span style={{fontSize: '10px'}}> Copied!</span>}
            </Button>
          </div>
        )
      ) : (
        <Button className="smart-id-button" onClick={handlePassword}>
          Päevaparool
        </Button>
      )}
    </div>
  );
};

export default PasswordSection;
