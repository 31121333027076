import './App.css';
import './styles/app.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useEffect} from "react";
import {
  Route,
  Routes,
  useNavigate
} from 'react-router-dom';
import { LoggedInPage } from './pages/loggedIn/LoggedInPage';
import { IdCard } from "./pages/loggedIn/logInSolutions/IdCard.js";
import { MobileId } from "./pages/loggedIn/logInSolutions/MobileId.js";
import { SmartId } from "./pages/loggedIn/logInSolutions/SmartId.js";
import { LoginPage } from './pages/LoginPage';
import NotFoundPage from "./pages/NotFoundPage.js";

function App() {
  const navigate = useNavigate();
  
  useEffect(() => {
    // Check if the component is mounted for the first time
    if (!sessionStorage.getItem('firstLoad')) {
      // Set an item in sessionStorage to indicate the first load
      sessionStorage.setItem('firstLoad', '1');
      // This block will run only on the first load, not on refresh
      console.log('First load');
      
      // Add logic here for the initial load
    } else {
      // This block will run on refresh
      console.log('Page refresh still!!');
      
      // Add logic here for the page refresh
      // For example, reset state or navigate to the default route
//      navigate('/');
    }
    
    // Cleanup logic if needed
    return () => {
      // Add cleanup logic here
    };
  }, []);
  
  return (
    <div>
      <Routes>
        <Route path="/" element={<LoginPage />}>
          <Route index element={<SmartId />} />
        </Route>
        <Route path="/login" element={<LoginPage />}>
          <Route path="smart-id" element={<SmartId />} />
          <Route path="mobile-id" element={<MobileId />} />
          <Route path="id-card" element={<IdCard />} />
          <Route path="id-card/:id" element={<IdCard />} />
        </Route>
        <Route path="/logged" element={<LoggedInPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
}

export default App;
