import React, { useState } from 'react';
import { InputGroup, Form, FormControl } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { useFetchCompaniesQuery } from '../../redux/api';
import { useNavigate } from 'react-router-dom';
import searchImg from '../../assets/images/search.svg';

export const SearchSection = ({ onSearch }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  
  // Fetch the list of companies
  const { data: companies, error: companiesError, isLoading: companiesIsLoading } = useFetchCompaniesQuery();
  
  // Handle search
  const handleSearch = (query) => {
    setSearchQuery(query);
    const filteredCompanies = companies.filter((company) => {
      return company.name.toLowerCase().includes(query.toLowerCase());
    });
    onSearch(filteredCompanies);
  };
  
  // Handle form submission
  const handleFormSubmit = (event) => {
    event.preventDefault(); // Prevent form submission
    // Call your handleSearch function or any other logic you need
    handleSearch(searchQuery);
  };
  
  if (companiesIsLoading) {
    return (
      <div className="navBarClass">
        <Container>
          <p>Laeb...</p> {/* Show loading message */}
        </Container>
      </div>
    );
  }
  
  console.log("companiesError", companiesError);
  if (companiesError) {
    navigate('/login/smart-id');
    return null;
  }
  
  return (
    <div className="navBarClass">
      {/* Search input and button */}
      <Container style={{ marginTop: '-22px' }}>
        <Form onSubmit={handleFormSubmit} inline>
          <InputGroup className="mb-3">
            <FormControl
              type="text"
              placeholder="Otsi ettevõtet..."
              className="mr-sm-2 grey-placeholder searchSection"
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
              style={{
                backgroundImage: `url(${searchImg})`,
                backgroundRepeat: 'no-repeat',
                paddingLeft: '15px',
                backgroundSize: '25px auto',
                backgroundPosition: 'calc(100% - 2%) center',
              }}
            />
          </InputGroup>
        </Form>
      </Container>
    </div>
  );
};
