import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Define the base query configuration
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BACKEND_URL,
  credentials: 'include',
  mode: 'cors',
});

// Define a service using a base URL and expected endpoints
export const noomApi = createApi({
  reducerPath: 'noomApi',
  baseQuery: baseQuery,
  tagTypes: ['auth'],
  endpoints: (builder) => ({
    isAllow: builder.query({
      query: ({ personalCode }) => `?act=query&qid=DAILYPW_ISALLOW&par.PERSONALCODE=${personalCode}`,
      providesTags: ['DAILYPW_ISALLOW'],
      // credentials: 'include',
    }),
    authorize: builder.query({
      query: ({ username, password }) => `?act=useraccess&user=${username}&pass=${password}`,
      invalidatesTags: ['DAILYPW_ISALLOW'],
      providesTags: ['auth'],
      // credentials: 'include',
    }),
    smartIdLogin: builder.mutation({
      query: (personalCode) => ({
        url: `auth/smart-id/login`,
        body: { personal_code: personalCode },
        method: 'POST',
        credentials: 'include',
      }),
    }),
    smartIdStatus: builder.mutation({
      query: (verificationCode) => {
        const queryParams = new URLSearchParams({
          verification_code: verificationCode,
         
        });
        return `auth/smart-id/status`;
        
      },
    }),
    mobileIdLogin: builder.mutation({
      query: ({personalCode, phoneNumber}) => ({
        url: `auth/mobile-id/login`,
        body: { personal_code: personalCode, phone_number: phoneNumber },
        method: 'POST',
      }),
    
    }),
    mobileIdStatus: builder.mutation({
      query: (verificationCode) => {
        const queryParams = new URLSearchParams({
          verification_code: verificationCode,
        });
        return `auth/mobile-id/status`;
      },
    }),
    
    idCardLogin: builder.query({
      query: (hash) => ({
        url: `auth/id-card/login/${hash}`,
        method: 'GET',
      }),
    }),
    fetchCompanies : builder.query({
      query: () => `user/companies`,
      transformResponse(response, meta, arg) {
        return response.companies.data;
      },
      method: 'GET',
    }),
    getPassword: builder.query({
      query: (regNo) => `/user/company/password/${regNo}`,
      transformResponse(response, meta, arg) {
        return response.password.data.response;
      },
      method: 'GET',
    }),
  }),
});

export const { useIsAllowQuery, useAuthorizeQuery, useSmartIdLoginMutation, useSmartIdStatusMutation, useMobileIdLoginMutation, useMobileIdStatusMutation,
useFetchCompaniesQuery, useGetPasswordQuery, useIdCardLoginQuery} = noomApi;
